export const dummySimulationFields = {
  total_net_profit: "Total net profit",
  gross_profit: "Gross profit",
  gross_loss: "Gross loss",
  commission: "Commission",
  profit_factor: "Profit factor",
  profit_per_month: "Profit per month",
  profit_per_quarter: "Profit per quarter",
  max_drawdown: "Max. drawdown",
  sharpe_ratio: "Sharpe ratio",
  sortino_ratio: "Sortino ratio",
  ulcer_index: "Ulcer index",
  r_squared: "R squared",
  probability: "Probability",
  total_of_trades: "Total # of trades",
  percent_profitable: "Percent profitable",
  winning_trades: "# of winning trades",
  losing_trades: "# of losing trades",
  even_trades: "# of even trades",
  total_slippage: "Total slippage",
  avg_trade: "Avg. trade",
  avg_winning_trade: "Avg. winning trade",
  avg_losing_trade: "Avg. losing trade",
  ratio_win_loss: "Ratio avg. win / avg. loss",
  max_consec_winners: "Max. consec. winners",
  max_consec_losers: "Max. consec. losers",
  largest_winning_trade: "Largest winning trade",
  largest_losing_trade: "Largest losing trade",
  avg_trades_per_day: "Avg. # of trades per day",
  avg_time_in_market: "Avg. time in market",
  avg_bars_in_trade: "Avg. bars in trade",
  max_time_to_recover: "Max. time to recover",
  longest_flat_period: "Longest flat period",
  avg_mae: "Avg. MAE",
  avg_mfe: "Avg. MFE",
  avg_etd: "Avg. ETD",
};

const tour_preMade_1_data = {
  all_trades: {
    total_net_profit: 565477.5,
    gross_profit: 1722287.5,
    gross_loss: -1156810,
    commission: 0,
    profit_factor: 1.81,
    profit_per_month: 3592.39,
    profit_per_quarter: 10777.17,
    max_drawdown: -38756,
    sharpe_ratio: 0.36,
    sortino_ratio: 7.49,
    ulcer_index: 0.02,
    r_squared: 0.93,
    probability: 0.02,
    total_of_trades: 7126,
    percent_profitable: 348.89,
    winning_trades: 4080,
    losing_trades: 2860,
    even_trades: 186,
    total_slippage: 0,
    avg_trade: 105.6,
    avg_winning_trade: 448.46,
    avg_losing_trade: -388.92,
    ratio_win_loss: 1.2,
    max_consec_winners: 72,
    max_consec_losers: 44,
    largest_winning_trade: 3860.42,
    largest_losing_trade: -1591.67,
    avg_trades_per_day: 0.36,
    avg_time_in_market: 214.41,
    avg_bars_in_trade: 25.66,
    max_time_to_recover: 436.54,
    longest_flat_period: 53.82,
    avg_mae: 325.98,
    avg_mfe: 471.46,
    avg_etd: 365.87,
    avg_trades_per_quarter: 178.15,
  },
  long_trades: {
    total_net_profit: 349425,
    gross_profit: 1037525,
    gross_loss: -688100,
    commission: 0,
    profit_factor: 1.88,
    profit_per_month: 2221.48,
    profit_per_quarter: 6664.44,
    max_drawdown: -34925,
    sharpe_ratio: 0.29,
    sortino_ratio: 5.16,
    ulcer_index: 0.02,
    r_squared: 0.91,
    probability: 0.38,
    total_of_trades: 4760,
    percent_profitable: 360.93,
    winning_trades: 2800,
    losing_trades: 1820,
    even_trades: 140,
    total_slippage: 0,
    avg_trade: 98.48,
    avg_winning_trade: 392.57,
    avg_losing_trade: -367.07,
    ratio_win_loss: 1.14,
    max_consec_winners: 77,
    max_consec_losers: 38,
    largest_winning_trade: 3506.25,
    largest_losing_trade: -1531.25,
    avg_trades_per_day: 0.24,
    avg_time_in_market: 218.96,
    avg_bars_in_trade: 26.64,
    max_time_to_recover: 628.7,
    longest_flat_period: 70.32,
    avg_mae: 300.8,
    avg_mfe: 418.74,
    avg_etd: 320.26,
    avg_trades_per_quarter: 119,
  },
  short_trades: {
    total_net_profit: 216052.5,
    gross_profit: 684762.5,
    gross_loss: -468710,
    commission: 0,
    profit_factor: 1.74,
    profit_per_month: 1375.86,
    profit_per_quarter: 4127.58,
    max_drawdown: -41062.5,
    sharpe_ratio: 0.21,
    sortino_ratio: 3.2,
    ulcer_index: 0.03,
    r_squared: 0.8,
    probability: 0.7,
    total_of_trades: 2366,
    percent_profitable: 327.65,
    winning_trades: 1280,
    losing_trades: 1040,
    even_trades: 46,
    total_slippage: 0,
    avg_trade: 113.6,
    avg_winning_trade: 548.65,
    avg_losing_trade: -420.59,
    ratio_win_loss: 1.34,
    max_consec_winners: 56,
    max_consec_losers: 36,
    largest_winning_trade: 3737.5,
    largest_losing_trade: -1425,
    avg_trades_per_day: 0.12,
    avg_time_in_market: 202.22,
    avg_bars_in_trade: 23.06,
    max_time_to_recover: 1032.63,
    longest_flat_period: 141.16,
    avg_mae: 366.51,
    avg_mfe: 558.6,
    avg_etd: 445,
    avg_trades_per_quarter: 59.15,
  },
  chart_data: [
    ["1/1/2008", 398.06],
    ["2/1/2008", 771.11],
    ["3/1/2008", 217.83],
    ["4/1/2008", 1985.67],
    ["5/1/2008", 2416.96],
    ["6/1/2008", 3046.18],
    ["7/1/2008", 2629.8],
    ["8/1/2008", 3249.97],
    ["9/1/2008", 3967.2],
    ["10/1/2008", 7321.33],
    ["11/1/2008", 8685.9],
    ["12/1/2008", 7826.96],
    ["1/1/2009", 9936.75],
    ["2/1/2009", 9057.86],
    ["3/1/2009", 10208.72],
    ["4/1/2009", 10447.34],
    ["5/1/2009", 11436.56],
    ["6/1/2009", 9850.14],
    ["7/1/2009", 10914.72],
    ["8/1/2009", 12085.42],
    ["9/1/2009", 12578.52],
    ["10/1/2009", 11293.45],
    ["11/1/2009", 12659.72],
    ["12/1/2009", 10902.35],
    ["1/1/2010", 12393.34],
    ["2/1/2010", 12222.04],
    ["3/1/2010", 14986.31],
    ["4/1/2010", 12229.02],
    ["5/1/2010", 11522.96],
    ["6/1/2010", 14343.92],
    ["7/1/2010", 13083.65],
    ["8/1/2010", 12951.75],
    ["9/1/2010", 15202.96],
    ["10/1/2010", 14969],
    ["11/1/2010", 13359.65],
    ["12/1/2010", 13392.69],
    ["1/1/2011", 15366.4],
    ["2/1/2011", 15900.88],
    ["3/1/2011", 13335.86],
    ["4/1/2011", 13343.61],
    ["5/1/2011", 12997.13],
    ["6/1/2011", 14951.3],
    ["7/1/2011", 15549.91],
    ["8/1/2011", 17288.1],
    ["9/1/2011", 14934.25],
    ["10/1/2011", 18302.74],
    ["11/1/2011", 18329.46],
    ["12/1/2011", 18657.39],
    ["1/1/2012", 18264.8],
    ["2/1/2012", 18242.21],
    ["3/1/2012", 18673.93],
    ["4/1/2012", 19110.48],
    ["5/1/2012", 18415.65],
    ["6/1/2012", 16980.99],
    ["7/1/2012", 18813.66],
    ["8/1/2012", 18685.81],
    ["9/1/2012", 19210.12],
    ["10/1/2012", 18993.22],
    ["11/1/2012", 17206.84],
    ["12/1/2012", 19419.34],
    ["1/1/2013", 19481.92],
    ["2/1/2013", 19997.52],
    ["3/1/2013", 19946.83],
    ["4/1/2013", 20513.12],
    ["5/1/2013", 19975.01],
    ["6/1/2013", 20398.62],
    ["7/1/2013", 19992.84],
    ["8/1/2013", 20314.22],
    ["9/1/2013", 20064.82],
    ["10/1/2013", 23637.2],
    ["11/1/2013", 21213.35],
    ["12/1/2013", 18535.18],
    ["1/1/2014", 21927.05],
    ["2/1/2014", 22678.86],
    ["3/1/2014", 20010.75],
    ["4/1/2014", 21200.33],
    ["5/1/2014", 24062.29],
    ["6/1/2014", 24269.1],
    ["7/1/2014", 24856.08],
    ["8/1/2014", 24652.63],
    ["9/1/2014", 21878.64],
    ["10/1/2014", 26661.61],
    ["11/1/2014", 28452.62],
    ["12/1/2014", 27030.48],
    ["1/1/2015", 24164.12],
    ["2/1/2015", 28604.1],
    ["3/1/2015", 25496.15],
    ["4/1/2015", 28484.6],
    ["5/1/2015", 24978.47],
    ["6/1/2015", 30061.66],
    ["7/1/2015", 30153.56],
    ["8/1/2015", 26784.2],
    ["9/1/2015", 31847.68],
    ["10/1/2015", 32642.85],
    ["11/1/2015", 33721.29],
    ["12/1/2015", 29252.86],
    ["1/1/2016", 35489.21],
    ["2/1/2016", 35094.12],
    ["3/1/2016", 35991.79],
    ["4/1/2016", 36248.51],
    ["5/1/2016", 31146.74],
    ["6/1/2016", 31763.47],
    ["7/1/2016", 31960.45],
    ["8/1/2016", 31595.72],
    ["9/1/2016", 33611.23],
    ["10/1/2016", 32900.67],
    ["11/1/2016", 38561.76],
    ["12/1/2016", 39074.25],
    ["1/1/2017", 38541.92],
    ["2/1/2017", 38595.02],
    ["3/1/2017", 34050.51],
    ["4/1/2017", 33320.05],
    ["5/1/2017", 38524.06],
    ["6/1/2017", 33342.81],
    ["7/1/2017", 33270.54],
    ["8/1/2017", 34627.43],
    ["9/1/2017", 40119.3],
    ["10/1/2017", 40628.18],
    ["11/1/2017", 40912.57],
    ["12/1/2017", 40635.67],
    ["1/1/2018", 42509.03],
    ["2/1/2018", 46599.97],
    ["3/1/2018", 41982.15],
    ["4/1/2018", 42313.32],
    ["5/1/2018", 42858.15],
    ["6/1/2018", 48475.9],
    ["7/1/2018", 49459.86],
    ["8/1/2018", 49447.1],
    ["9/1/2018", 49648.82],
    ["10/1/2018", 50669.33],
    ["11/1/2018", 46569.83],
    ["12/1/2018", 55560.09],
    ["1/1/2019", 57205.6],
    ["2/1/2019", 56088.27],
    ["3/1/2019", 48146.82],
    ["4/1/2019", 56518.95],
    ["5/1/2019", 56639.38],
    ["6/1/2019", 56645.66],
    ["7/1/2019", 56854.45],
    ["8/1/2019", 61080.82],
    ["9/1/2019", 52392.13],
    ["10/1/2019", 61451.33],
    ["11/1/2019", 61471.67],
    ["12/1/2019", 61208.39],
    ["1/1/2020", 61762.18],
    ["2/1/2020", 54689.02],
    ["3/1/2020", 63971.56],
    ["4/1/2020", 67081.82],
    ["5/1/2020", 68680.78],
    ["6/1/2020", 73644.74],
    ["7/1/2020", 71895.42],
    ["8/1/2020", 73408.43],
    ["9/1/2020", 74703.51],
    ["10/1/2020", 65960.96],
    ["11/1/2020", 77347.64],
    ["12/1/2020", 75816.6],
    ["1/1/2021", 76198.06],
    ["2/1/2021", 73855.9],
    ["3/1/2021", 62389.24],
    ["4/1/2021", 74367.27],
    ["5/1/2021", 73703.56],
    ["6/1/2021", 72777.17],
  ],
};

const tour_preMade_2_data = {
  all_trades: {
    total_net_profit: 906862.5,
    gross_profit: 2670442.5,
    gross_loss: -1763580,
    commission: 0,
    profit_factor: 1.77,
    profit_per_month: 5763.63,
    profit_per_quarter: 17290.89,
    max_drawdown: -57241,
    sharpe_ratio: 0.38,
    sortino_ratio: 11.59,
    ulcer_index: 0.02,
    r_squared: 0.93,
    probability: 0.01,
    total_of_trades: 10180,
    percent_profitable: 512.4,
    winning_trades: 5794,
    losing_trades: 4190,
    even_trades: 196,
    total_slippage: 0,
    avg_trade: 119.25,
    avg_winning_trade: 517.05,
    avg_losing_trade: -413.37,
    ratio_win_loss: 1.28,
    max_consec_winners: 106,
    max_consec_losers: 64,
    largest_winning_trade: 4247.5,
    largest_losing_trade: -1461.11,
    avg_trades_per_day: 0.34,
    avg_time_in_market: 206.17,
    avg_bars_in_trade: 20.6,
    max_time_to_recover: 401.57,
    longest_flat_period: 75.48,
    avg_mae: 332.29,
    avg_mfe: 526.95,
    avg_etd: 407.7,
    avg_trades_per_quarter: 254.5,
  },
  long_trades: {
    total_net_profit: 527360,
    gross_profit: 1513950,
    gross_loss: -986590,
    commission: 0,
    profit_factor: 1.84,
    profit_per_month: 3357.24,
    profit_per_quarter: 10071.72,
    max_drawdown: -55840,
    sharpe_ratio: 0.29,
    sortino_ratio: 7.39,
    ulcer_index: 0.02,
    r_squared: 0.89,
    probability: 0.27,
    total_of_trades: 6378,
    percent_profitable: 535.44,
    winning_trades: 3758,
    losing_trades: 2476,
    even_trades: 144,
    total_slippage: 0,
    avg_trade: 112.99,
    avg_winning_trade: 451.41,
    avg_losing_trade: -397.61,
    ratio_win_loss: 1.19,
    max_consec_winners: 108,
    max_consec_losers: 58,
    largest_winning_trade: 3825.83,
    largest_losing_trade: -1420.83,
    avg_trades_per_day: 0.21,
    avg_time_in_market: 214.09,
    avg_bars_in_trade: 21.55,
    max_time_to_recover: 680.33,
    longest_flat_period: 99.69,
    avg_mae: 307.62,
    avg_mfe: 466.38,
    avg_etd: 353.4,
    avg_trades_per_quarter: 159.45,
  },
  short_trades: {
    total_net_profit: 379502.5,
    gross_profit: 1156492.5,
    gross_loss: -776990,
    commission: 0,
    profit_factor: 1.71,
    profit_per_month: 2415.69,
    profit_per_quarter: 7247.07,
    max_drawdown: -65432.5,
    sharpe_ratio: 0.22,
    sortino_ratio: 5.08,
    ulcer_index: 0.03,
    r_squared: 0.84,
    probability: 0.53,
    total_of_trades: 3802,
    percent_profitable: 478.44,
    winning_trades: 2036,
    losing_trades: 1714,
    even_trades: 52,
    total_slippage: 0,
    avg_trade: 126.49,
    avg_winning_trade: 624,
    avg_losing_trade: -435.52,
    ratio_win_loss: 1.46,
    max_consec_winners: 88,
    max_consec_losers: 64,
    largest_winning_trade: 4165.56,
    largest_losing_trade: -1350,
    avg_trades_per_day: 0.13,
    avg_time_in_market: 192.43,
    avg_bars_in_trade: 18.52,
    max_time_to_recover: 1008.3,
    longest_flat_period: 151.28,
    avg_mae: 368.41,
    avg_mfe: 614.54,
    avg_etd: 488.06,
    avg_trades_per_quarter: 95.05,
  },
  chart_data: [
    ["1/1/2008", 398.06],
    ["2/1/2008", 771.11],
    ["3/1/2008", 217.83],
    ["4/1/2008", 1985.67],
    ["5/1/2008", 2416.96],
    ["6/1/2008", 3046.18],
    ["7/1/2008", 2629.8],
    ["8/1/2008", 3249.97],
    ["9/1/2008", 3967.2],
    ["10/1/2008", 7321.33],
    ["11/1/2008", 8685.9],
    ["12/1/2008", 7826.96],
    ["1/1/2009", 9936.75],
    ["2/1/2009", 9057.86],
    ["3/1/2009", 10208.72],
    ["4/1/2009", 10447.34],
    ["5/1/2009", 11436.56],
    ["6/1/2009", 9850.14],
    ["7/1/2009", 10914.72],
    ["8/1/2009", 12085.42],
    ["9/1/2009", 12578.52],
    ["10/1/2009", 11293.45],
    ["11/1/2009", 12659.72],
    ["12/1/2009", 10902.35],
    ["1/1/2010", 12393.34],
    ["2/1/2010", 12222.04],
    ["3/1/2010", 14986.31],
    ["4/1/2010", 12229.02],
    ["5/1/2010", 11522.96],
    ["6/1/2010", 14343.92],
    ["7/1/2010", 13083.65],
    ["8/1/2010", 12951.75],
    ["9/1/2010", 15202.96],
    ["10/1/2010", 14969],
    ["11/1/2010", 13359.65],
    ["12/1/2010", 13392.69],
    ["1/1/2011", 15366.4],
    ["2/1/2011", 15900.88],
    ["3/1/2011", 13335.86],
    ["4/1/2011", 13343.61],
    ["5/1/2011", 12997.13],
    ["6/1/2011", 14951.3],
    ["7/1/2011", 15549.91],
    ["8/1/2011", 17288.1],
    ["9/1/2011", 14934.25],
    ["10/1/2011", 18302.74],
    ["11/1/2011", 18329.46],
    ["12/1/2011", 18657.39],
    ["1/1/2012", 18264.8],
    ["2/1/2012", 18242.21],
    ["3/1/2012", 18673.93],
    ["4/1/2012", 19110.48],
    ["5/1/2012", 18415.65],
    ["6/1/2012", 16980.99],
    ["7/1/2012", 18813.66],
    ["8/1/2012", 18685.81],
    ["9/1/2012", 19210.12],
    ["10/1/2012", 18993.22],
    ["11/1/2012", 17206.84],
    ["12/1/2012", 19419.34],
    ["1/1/2013", 19481.92],
    ["2/1/2013", 19997.52],
    ["3/1/2013", 19946.83],
    ["4/1/2013", 20513.12],
    ["5/1/2013", 19975.01],
    ["6/1/2013", 20398.62],
    ["7/1/2013", 19992.84],
    ["8/1/2013", 20314.22],
    ["9/1/2013", 20064.82],
    ["10/1/2013", 23637.2],
    ["11/1/2013", 21213.35],
    ["12/1/2013", 18535.18],
    ["1/1/2014", 21927.05],
    ["2/1/2014", 22678.86],
    ["3/1/2014", 20010.75],
    ["4/1/2014", 21200.33],
    ["5/1/2014", 24062.29],
    ["6/1/2014", 24269.1],
    ["7/1/2014", 24856.08],
    ["8/1/2014", 24652.63],
    ["9/1/2014", 21878.64],
    ["10/1/2014", 26661.61],
    ["11/1/2014", 28452.62],
    ["12/1/2014", 27030.48],
    ["1/1/2015", 24164.12],
    ["2/1/2015", 28604.1],
    ["3/1/2015", 25496.15],
    ["4/1/2015", 28484.6],
    ["5/1/2015", 24978.47],
    ["6/1/2015", 30061.66],
    ["7/1/2015", 30153.56],
    ["8/1/2015", 26784.2],
    ["9/1/2015", 31847.68],
    ["10/1/2015", 32642.85],
    ["11/1/2015", 33721.29],
    ["12/1/2015", 29252.86],
    ["1/1/2016", 35489.21],
    ["2/1/2016", 35094.12],
    ["3/1/2016", 35991.79],
    ["4/1/2016", 36248.51],
    ["5/1/2016", 31146.74],
    ["6/1/2016", 31763.47],
    ["7/1/2016", 31960.45],
    ["8/1/2016", 31595.72],
    ["9/1/2016", 33611.23],
    ["10/1/2016", 32900.67],
    ["11/1/2016", 38561.76],
    ["12/1/2016", 39074.25],
    ["1/1/2017", 38541.92],
    ["2/1/2017", 38595.02],
    ["3/1/2017", 34050.51],
    ["4/1/2017", 33320.05],
    ["5/1/2017", 38524.06],
    ["6/1/2017", 33342.81],
    ["7/1/2017", 33270.54],
    ["8/1/2017", 34627.43],
    ["9/1/2017", 40119.3],
    ["10/1/2017", 40628.18],
    ["11/1/2017", 40912.57],
    ["12/1/2017", 40635.67],
    ["1/1/2018", 42509.03],
    ["2/1/2018", 46599.97],
    ["3/1/2018", 41982.15],
    ["4/1/2018", 42313.32],
    ["5/1/2018", 42858.15],
    ["6/1/2018", 48475.9],
    ["7/1/2018", 49459.86],
    ["8/1/2018", 49447.1],
    ["9/1/2018", 49648.82],
    ["10/1/2018", 50669.33],
    ["11/1/2018", 46569.83],
    ["12/1/2018", 55560.09],
    ["1/1/2019", 57205.6],
    ["2/1/2019", 56088.27],
    ["3/1/2019", 48146.82],
    ["4/1/2019", 56518.95],
    ["5/1/2019", 56639.38],
    ["6/1/2019", 56645.66],
    ["7/1/2019", 56854.45],
    ["8/1/2019", 61080.82],
    ["9/1/2019", 52392.13],
    ["10/1/2019", 61451.33],
    ["11/1/2019", 61471.67],
    ["12/1/2019", 61208.39],
    ["1/1/2020", 61762.18],
    ["2/1/2020", 54689.02],
    ["3/1/2020", 63971.56],
    ["4/1/2020", 67081.82],
    ["5/1/2020", 68680.78],
    ["6/1/2020", 73644.74],
    ["7/1/2020", 71895.42],
    ["8/1/2020", 73408.43],
    ["9/1/2020", 74703.51],
    ["10/1/2020", 65960.96],
    ["11/1/2020", 77347.64],
    ["12/1/2020", 75816.6],
    ["1/1/2021", 76198.06],
    ["2/1/2021", 73855.9],
    ["3/1/2021", 62389.24],
    ["4/1/2021", 74367.27],
    ["5/1/2021", 73703.56],
    ["6/1/2021", 72777.17],
  ],
};

const tour_preMade_3_data = {
  all_trades: {
    total_net_profit: 1105387,
    gross_profit: 2670442.5,
    gross_loss: -1763580,
    commission: 0,
    profit_factor: 1.77,
    profit_per_month: 5763.63,
    profit_per_quarter: 17290.89,
    max_drawdown: -57241,
    sharpe_ratio: 0.38,
    sortino_ratio: 11.59,
    ulcer_index: 0.02,
    r_squared: 0.93,
    probability: 0.01,
    total_of_trades: 10180,
    percent_profitable: 512.4,
    winning_trades: 5794,
    losing_trades: 4190,
    even_trades: 196,
    total_slippage: 0,
    avg_trade: 119.25,
    avg_winning_trade: 517.05,
    avg_losing_trade: -413.37,
    ratio_win_loss: 1.28,
    max_consec_winners: 106,
    max_consec_losers: 64,
    largest_winning_trade: 4247.5,
    largest_losing_trade: -1461.11,
    avg_trades_per_day: 0.34,
    avg_time_in_market: 206.17,
    avg_bars_in_trade: 20.6,
    max_time_to_recover: 401.57,
    longest_flat_period: 75.48,
    avg_mae: 332.29,
    avg_mfe: 526.95,
    avg_etd: 407.7,
    avg_trades_per_quarter: 254.5,
  },
  long_trades: {
    total_net_profit: 704517,
    gross_profit: 1513950,
    gross_loss: -986590,
    commission: 0,
    profit_factor: 1.84,
    profit_per_month: 3357.24,
    profit_per_quarter: 10071.72,
    max_drawdown: -55840,
    sharpe_ratio: 0.29,
    sortino_ratio: 7.39,
    ulcer_index: 0.02,
    r_squared: 0.89,
    probability: 0.27,
    total_of_trades: 6378,
    percent_profitable: 535.44,
    winning_trades: 3758,
    losing_trades: 2476,
    even_trades: 144,
    total_slippage: 0,
    avg_trade: 112.99,
    avg_winning_trade: 451.41,
    avg_losing_trade: -397.61,
    ratio_win_loss: 1.19,
    max_consec_winners: 108,
    max_consec_losers: 58,
    largest_winning_trade: 3825.83,
    largest_losing_trade: -1420.83,
    avg_trades_per_day: 0.21,
    avg_time_in_market: 214.09,
    avg_bars_in_trade: 21.55,
    max_time_to_recover: 680.33,
    longest_flat_period: 99.69,
    avg_mae: 307.62,
    avg_mfe: 466.38,
    avg_etd: 353.4,
    avg_trades_per_quarter: 159.45,
  },
  short_trades: {
    total_net_profit: 400870,
    gross_profit: 1156492.5,
    gross_loss: -776990,
    commission: 0,
    profit_factor: 1.71,
    profit_per_month: 2415.69,
    profit_per_quarter: 7247.07,
    max_drawdown: -65432.5,
    sharpe_ratio: 0.22,
    sortino_ratio: 5.08,
    ulcer_index: 0.03,
    r_squared: 0.84,
    probability: 0.53,
    total_of_trades: 3802,
    percent_profitable: 478.44,
    winning_trades: 2036,
    losing_trades: 1714,
    even_trades: 52,
    total_slippage: 0,
    avg_trade: 126.49,
    avg_winning_trade: 624,
    avg_losing_trade: -435.52,
    ratio_win_loss: 1.46,
    max_consec_winners: 88,
    max_consec_losers: 64,
    largest_winning_trade: 4165.56,
    largest_losing_trade: -1350,
    avg_trades_per_day: 0.13,
    avg_time_in_market: 192.43,
    avg_bars_in_trade: 18.52,
    max_time_to_recover: 1008.3,
    longest_flat_period: 151.28,
    avg_mae: 368.41,
    avg_mfe: 614.54,
    avg_etd: 488.06,
    avg_trades_per_quarter: 95.05,
  },
  chart_data: [
    ["1/1/2008", 398.06],
    ["2/1/2008", 771.11],
    ["3/1/2008", 217.83],
    ["4/1/2008", 1985.67],
    ["5/1/2008", 2416.96],
    ["6/1/2008", 3046.18],
    ["7/1/2008", 2629.8],
    ["8/1/2008", 3249.97],
    ["9/1/2008", 3967.2],
    ["10/1/2008", 7321.33],
    ["11/1/2008", 8685.9],
    ["12/1/2008", 7826.96],
    ["1/1/2009", 9936.75],
    ["2/1/2009", 9057.86],
    ["3/1/2009", 10208.72],
    ["4/1/2009", 10447.34],
    ["5/1/2009", 11436.56],
    ["6/1/2009", 9850.14],
    ["7/1/2009", 10914.72],
    ["8/1/2009", 12085.42],
    ["9/1/2009", 12578.52],
    ["10/1/2009", 11293.45],
    ["11/1/2009", 12659.72],
    ["12/1/2009", 10902.35],
    ["1/1/2010", 12393.34],
    ["2/1/2010", 12222.04],
    ["3/1/2010", 14986.31],
    ["4/1/2010", 12229.02],
    ["5/1/2010", 11522.96],
    ["6/1/2010", 14343.92],
    ["7/1/2010", 13083.65],
    ["8/1/2010", 12951.75],
    ["9/1/2010", 15202.96],
    ["10/1/2010", 14969],
    ["11/1/2010", 13359.65],
    ["12/1/2010", 13392.69],
    ["1/1/2011", 15366.4],
    ["2/1/2011", 15900.88],
    ["3/1/2011", 13335.86],
    ["4/1/2011", 13343.61],
    ["5/1/2011", 12997.13],
    ["6/1/2011", 14951.3],
    ["7/1/2011", 15549.91],
    ["8/1/2011", 17288.1],
    ["9/1/2011", 14934.25],
    ["10/1/2011", 18302.74],
    ["11/1/2011", 18329.46],
    ["12/1/2011", 18657.39],
    ["1/1/2012", 18264.8],
    ["2/1/2012", 18242.21],
    ["3/1/2012", 18673.93],
    ["4/1/2012", 19110.48],
    ["5/1/2012", 18415.65],
    ["6/1/2012", 16980.99],
    ["7/1/2012", 18813.66],
    ["8/1/2012", 18685.81],
    ["9/1/2012", 19210.12],
    ["10/1/2012", 18993.22],
    ["11/1/2012", 17206.84],
    ["12/1/2012", 19419.34],
    ["1/1/2013", 19481.92],
    ["2/1/2013", 19997.52],
    ["3/1/2013", 19946.83],
    ["4/1/2013", 20513.12],
    ["5/1/2013", 19975.01],
    ["6/1/2013", 20398.62],
    ["7/1/2013", 19992.84],
    ["8/1/2013", 20314.22],
    ["9/1/2013", 20064.82],
    ["10/1/2013", 23637.2],
    ["11/1/2013", 21213.35],
    ["12/1/2013", 18535.18],
    ["1/1/2014", 21927.05],
    ["2/1/2014", 22678.86],
    ["3/1/2014", 20010.75],
    ["4/1/2014", 21200.33],
    ["5/1/2014", 24062.29],
    ["6/1/2014", 24269.1],
    ["7/1/2014", 24856.08],
    ["8/1/2014", 24652.63],
    ["9/1/2014", 21878.64],
    ["10/1/2014", 26661.61],
    ["11/1/2014", 28452.62],
    ["12/1/2014", 27030.48],
    ["1/1/2015", 24164.12],
    ["2/1/2015", 28604.1],
    ["3/1/2015", 25496.15],
    ["4/1/2015", 28484.6],
    ["5/1/2015", 24978.47],
    ["6/1/2015", 30061.66],
    ["7/1/2015", 30153.56],
    ["8/1/2015", 26784.2],
    ["9/1/2015", 31847.68],
    ["10/1/2015", 32642.85],
    ["11/1/2015", 33721.29],
    ["12/1/2015", 29252.86],
    ["1/1/2016", 35489.21],
    ["2/1/2016", 35094.12],
    ["3/1/2016", 35991.79],
    ["4/1/2016", 36248.51],
    ["5/1/2016", 31146.74],
    ["6/1/2016", 31763.47],
    ["7/1/2016", 31960.45],
    ["8/1/2016", 31595.72],
    ["9/1/2016", 33611.23],
    ["10/1/2016", 32900.67],
    ["11/1/2016", 38561.76],
    ["12/1/2016", 39074.25],
    ["1/1/2017", 38541.92],
    ["2/1/2017", 38595.02],
    ["3/1/2017", 34050.51],
    ["4/1/2017", 33320.05],
    ["5/1/2017", 38524.06],
    ["6/1/2017", 33342.81],
    ["7/1/2017", 33270.54],
    ["8/1/2017", 34627.43],
    ["9/1/2017", 40119.3],
    ["10/1/2017", 40628.18],
    ["11/1/2017", 40912.57],
    ["12/1/2017", 40635.67],
    ["1/1/2018", 42509.03],
    ["2/1/2018", 46599.97],
    ["3/1/2018", 41982.15],
    ["4/1/2018", 42313.32],
    ["5/1/2018", 42858.15],
    ["6/1/2018", 48475.9],
    ["7/1/2018", 49459.86],
    ["8/1/2018", 49447.1],
    ["9/1/2018", 49648.82],
    ["10/1/2018", 50669.33],
    ["11/1/2018", 46569.83],
    ["12/1/2018", 55560.09],
    ["1/1/2019", 57205.6],
    ["2/1/2019", 56088.27],
    ["3/1/2019", 48146.82],
    ["4/1/2019", 56518.95],
    ["5/1/2019", 56639.38],
    ["6/1/2019", 56645.66],
    ["7/1/2019", 56854.45],
    ["8/1/2019", 61080.82],
    ["9/1/2019", 52392.13],
    ["10/1/2019", 61451.33],
    ["11/1/2019", 61471.67],
    ["12/1/2019", 61208.39],
    ["1/1/2020", 61762.18],
    ["2/1/2020", 54689.02],
    ["3/1/2020", 63971.56],
    ["4/1/2020", 67081.82],
    ["5/1/2020", 68680.78],
    ["6/1/2020", 73644.74],
    ["7/1/2020", 71895.42],
    ["8/1/2020", 73408.43],
    ["9/1/2020", 74703.51],
    ["10/1/2020", 65960.96],
    ["11/1/2020", 77347.64],
    ["12/1/2020", 75816.6],
    ["1/1/2021", 76198.06],
    ["2/1/2021", 73855.9],
    ["3/1/2021", 62389.24],
    ["4/1/2021", 74367.27],
    ["5/1/2021", 73703.56],
    ["6/1/2021", 72777.17],
  ],
};

const tour_custom_1_data = {
  all_trades: {
    total_net_profit: 91262.5,
    gross_profit: 376250,
    gross_loss: -284987.5,
    commission: 0,
    profit_factor: 1.32,
    profit_per_month: 579.9,
    profit_per_quarter: 1739.7,
    max_drawdown: -8712.5,
    sharpe_ratio: 0.32,
    sortino_ratio: 0.58,
    ulcer_index: 0.02,
    r_squared: 0.98,
    probability: 0,
    total_of_trades: 1862,
    percent_profitable: 50.81,
    winning_trades: 946,
    losing_trades: 808,
    even_trades: 108,
    total_slippage: 0,
    avg_trade: 49.01,
    avg_winning_trade: 397.73,
    avg_losing_trade: -352.71,
    ratio_win_loss: 1.13,
    max_consec_winners: 14,
    max_consec_losers: 11,
    largest_winning_trade: 3412.5,
    largest_losing_trade: -1250,
    avg_trades_per_day: 0.56,
    avg_time_in_market: 243.46,
    avg_bars_in_trade: 48.69,
    max_time_to_recover: 309,
    longest_flat_period: 16.76,
    avg_mae: 267.43,
    avg_mfe: 413.73,
    avg_etd: 364.72,
    avg_trades_per_quarter: 46.55,
  },
  long_trades: {
    total_net_profit: 59650,
    gross_profit: 259662.5,
    gross_loss: -200012.5,
    commission: 0,
    profit_factor: 1.3,
    profit_per_month: 379.82,
    profit_per_quarter: 1139.46,
    max_drawdown: -10712.5,
    sharpe_ratio: 0.28,
    sortino_ratio: 0.57,
    ulcer_index: 0.02,
    r_squared: 0.98,
    probability: 0.02,
    total_of_trades: 1406,
    percent_profitable: 51.99,
    winning_trades: 731,
    losing_trades: 595,
    even_trades: 80,
    total_slippage: 0,
    avg_trade: 42.43,
    avg_winning_trade: 355.22,
    avg_losing_trade: -336.16,
    ratio_win_loss: 1.06,
    max_consec_winners: 15,
    max_consec_losers: 8,
    largest_winning_trade: 3412.5,
    largest_losing_trade: -887.5,
    avg_trades_per_day: 0.43,
    avg_time_in_market: 256.78,
    avg_bars_in_trade: 51.36,
    max_time_to_recover: 364,
    longest_flat_period: 21.76,
    avg_mae: 254.65,
    avg_mfe: 366.36,
    avg_etd: 323.93,
    avg_trades_per_quarter: 35.15,
  },
  short_trades: {
    total_net_profit: 31612.5,
    gross_profit: 116587.5,
    gross_loss: -84975,
    commission: 0,
    profit_factor: 1.37,
    profit_per_month: 200.87,
    profit_per_quarter: 602.61,
    max_drawdown: -5812.5,
    sharpe_ratio: 0.16,
    sortino_ratio: 0.26,
    ulcer_index: 0.04,
    r_squared: 0.88,
    probability: 2.31,
    total_of_trades: 456,
    percent_profitable: 47.15,
    winning_trades: 215,
    losing_trades: 213,
    even_trades: 28,
    total_slippage: 0,
    avg_trade: 69.33,
    avg_winning_trade: 542.27,
    avg_losing_trade: -398.94,
    ratio_win_loss: 1.36,
    max_consec_winners: 8,
    max_consec_losers: 7,
    largest_winning_trade: 3062.5,
    largest_losing_trade: -1250,
    avg_trades_per_day: 0.14,
    avg_time_in_market: 202.37,
    avg_bars_in_trade: 40.47,
    max_time_to_recover: 1148,
    longest_flat_period: 103.77,
    avg_mae: 306.83,
    avg_mfe: 559.79,
    avg_etd: 490.46,
    avg_trades_per_quarter: 11.4,
  },
  chart_data: [
    ["1/1/2008", 1527.58],
    ["2/1/2008", 2912.92],
    ["3/1/2008", 1136.62],
    ["4/1/2008", 2209.46],
    ["5/1/2008", 2920.66],
    ["6/1/2008", 3873.24],
    ["7/1/2008", 3529.7],
    ["8/1/2008", 6490.04],
    ["9/1/2008", 3543.48],
    ["10/1/2008", 8384.42],
    ["11/1/2008", 10107.26],
    ["12/1/2008", 11409.84],
    ["1/1/2009", 13763.28],
    ["2/1/2009", 11023.62],
    ["3/1/2009", 13143.44],
    ["4/1/2009", 13681.8],
    ["5/1/2009", 13979.64],
    ["6/1/2009", 14781.36],
    ["7/1/2009", 16737.82],
    ["8/1/2009", 16480.06],
    ["9/1/2009", 16924.02],
    ["10/1/2009", 16942.12],
    ["11/1/2009", 16460.22],
    ["12/1/2009", 16473.58],
    ["1/1/2010", 16037.8],
    ["2/1/2010", 16073.14],
    ["3/1/2010", 16533.48],
    ["4/1/2010", 16559.34],
    ["5/1/2010", 15265.8],
    ["6/1/2010", 17884.76],
    ["7/1/2010", 18070.1],
    ["8/1/2010", 17467.08],
    ["9/1/2010", 17486.04],
    ["10/1/2010", 16758.02],
    ["11/1/2010", 16692.5],
    ["12/1/2010", 16681.72],
    ["1/1/2011", 16158.44],
    ["2/1/2011", 16475.68],
    ["3/1/2011", 15635.16],
    ["4/1/2011", 16473.52],
    ["5/1/2011", 16679.12],
    ["6/1/2011", 17718.34],
    ["7/1/2011", 17932.56],
    ["8/1/2011", 18351.52],
    ["9/1/2011", 19529.1],
    ["10/1/2011", 21094.18],
    ["11/1/2011", 19280.38],
    ["12/1/2011", 19765.72],
    ["1/1/2012", 19304.08],
    ["2/1/2012", 19217.44],
    ["3/1/2012", 19298.04],
    ["4/1/2012", 20178.64],
    ["5/1/2012", 19305.36],
    ["6/1/2012", 19257.08],
    ["7/1/2012", 19101.9],
    ["8/1/2012", 19336.38],
    ["9/1/2012", 19320.86],
    ["10/1/2012", 19738.96],
    ["11/1/2012", 19279.04],
    ["12/1/2012", 18472.14],
    ["1/1/2013", 18410.5],
    ["2/1/2013", 17948.86],
    ["3/1/2013", 18269.98],
    ["4/1/2013", 20717.82],
    ["5/1/2013", 21348.42],
    ["6/1/2013", 21642.38],
    ["7/1/2013", 21336.34],
    ["8/1/2013", 21274.7],
    ["9/1/2013", 20880.3],
    ["10/1/2013", 21436.76],
    ["11/1/2013", 21567.36],
    ["12/1/2013", 21265.2],
    ["1/1/2014", 22029.42],
    ["2/1/2014", 21951.4],
    ["3/1/2014", 20853.12],
    ["4/1/2014", 21417.34],
    ["5/1/2014", 22044.06],
    ["6/1/2014", 22377.68],
    ["7/1/2014", 23770.78],
    ["8/1/2014", 24134.14],
    ["9/1/2014", 24798.36],
    ["10/1/2014", 26025.94],
    ["11/1/2014", 26940.16],
    ["12/1/2014", 28000.5],
    ["1/1/2015", 29394.46],
    ["2/1/2015", 30547.04],
    ["3/1/2015", 31328.5],
    ["4/1/2015", 30359.96],
    ["5/1/2015", 30886.68],
    ["6/1/2015", 32784.52],
    ["7/1/2015", 32812.1],
    ["8/1/2015", 33574.16],
    ["9/1/2015", 33182.34],
    ["10/1/2015", 33227.16],
    ["11/1/2015", 33433.62],
    ["12/1/2015", 33637.06],
    ["1/1/2016", 35394.38],
    ["2/1/2016", 35831.44],
    ["3/1/2016", 37562.9],
    ["4/1/2016", 38010.74],
    ["5/1/2016", 37508.58],
    ["6/1/2016", 39344.78],
    ["7/1/2016", 38759],
    ["8/1/2016", 38768.48],
    ["9/1/2016", 40795.2],
    ["10/1/2016", 40467.18],
    ["11/1/2016", 40751.66],
    ["12/1/2016", 41657.26],
    ["1/1/2017", 42300.36],
    ["2/1/2017", 42913.72],
    ["3/1/2017", 43174.06],
    ["4/1/2017", 42455.52],
    ["5/1/2017", 43161.98],
    ["6/1/2017", 42725.34],
    ["7/1/2017", 43530.94],
    ["8/1/2017", 45032.66],
    ["9/1/2017", 45793],
    ["10/1/2017", 47036.96],
    ["11/1/2017", 46605.06],
    ["12/1/2017", 47039.54],
    ["1/1/2018", 50488.24],
    ["2/1/2018", 56614.96],
    ["3/1/2018", 59263.66],
    ["4/1/2018", 59067.1],
    ["5/1/2018", 60307.18],
    ["6/1/2018", 57514.5],
    ["7/1/2018", 59653.72],
    ["8/1/2018", 59010.18],
    ["9/1/2018", 59211.9],
    ["10/1/2018", 58648.1],
    ["11/1/2018", 62238.18],
    ["12/1/2018", 61570.5],
    ["1/1/2019", 62944.2],
    ["2/1/2019", 62587.3],
    ["3/1/2019", 63444.62],
    ["4/1/2019", 63787.72],
    ["5/1/2019", 64149.78],
    ["6/1/2019", 64710.12],
    ["7/1/2019", 64529.94],
    ["8/1/2019", 69301.48],
    ["9/1/2019", 68499.32],
    ["10/1/2019", 69234.66],
    ["11/1/2019", 69395],
    ["12/1/2019", 69405.34],
    ["1/1/2020", 70387.66],
    ["2/1/2020", 72166.1],
    ["3/1/2020", 66928.16],
    ["4/1/2020", 68244.1],
    ["5/1/2020", 67363.06],
    ["6/1/2020", 71182.88],
    ["7/1/2020", 67127.7],
    ["8/1/2020", 68405.28],
    ["9/1/2020", 69321.22],
    ["10/1/2020", 73008.28],
    ["11/1/2020", 69531.98],
    ["12/1/2020", 67326.8],
    ["1/1/2021", 65997.48],
    ["2/1/2021", 62354.8],
    ["3/1/2021", 59387.12],
    ["4/1/2021", 60328.06],
    ["5/1/2021", 60077.62],
    ["6/1/2021", 57612.96],
  ],
};

const tour_custom_2_data = {
  all_trades: {
    total_net_profit: 174000,
    gross_profit: 580875,
    gross_loss: -406875,
    commission: 0,
    profit_factor: 1.5,
    profit_per_month: 1113.18,
    profit_per_quarter: 3339.54,
    max_drawdown: -15462.5,
    sharpe_ratio: 0.3,
    sortino_ratio: 1.9,
    ulcer_index: 0.02,
    r_squared: 0.94,
    probability: 0,
    total_of_trades: 2304,
    percent_profitable: 109.63,
    winning_trades: 1206,
    losing_trades: 987,
    even_trades: 111,
    total_slippage: 0,
    avg_trade: 118.1,
    avg_winning_trade: 592.38,
    avg_losing_trade: -516.82,
    ratio_win_loss: 1.14,
    max_consec_winners: 25,
    max_consec_losers: 16,
    largest_winning_trade: 4831.25,
    largest_losing_trade: -1750,
    avg_trades_per_day: 0.35,
    avg_time_in_market: 244.52,
    avg_bars_in_trade: 28.62,
    max_time_to_recover: 367.5,
    longest_flat_period: 82.26,
    avg_mae: 413.26,
    avg_mfe: 592.36,
    avg_etd: 474.26,
    avg_trades_per_quarter: 57.6,
  },
  long_trades: {
    total_net_profit: 95775,
    gross_profit: 348612.5,
    gross_loss: -252837.5,
    commission: 0,
    profit_factor: 1.49,
    profit_per_month: 613.7,
    profit_per_quarter: 1841.1,
    max_drawdown: -17462.5,
    sharpe_ratio: 0.23,
    sortino_ratio: 1.26,
    ulcer_index: 0.02,
    r_squared: 0.92,
    probability: 0.12,
    total_of_trades: 1626,
    percent_profitable: 113.81,
    winning_trades: 867,
    losing_trades: 677,
    even_trades: 82,
    total_slippage: 0,
    avg_trade: 103.32,
    avg_winning_trade: 504.63,
    avg_losing_trade: -490.18,
    ratio_win_loss: 1.04,
    max_consec_winners: 23,
    max_consec_losers: 12,
    largest_winning_trade: 4062.5,
    largest_losing_trade: -1568.75,
    avg_trades_per_day: 0.25,
    avg_time_in_market: 246.99,
    avg_bars_in_trade: 29.81,
    max_time_to_recover: 374.02,
    longest_flat_period: 120.79,
    avg_mae: 388.8,
    avg_mfe: 514.38,
    avg_etd: 411.06,
    avg_trades_per_quarter: 40.65,
  },
  short_trades: {
    total_net_profit: 78225,
    gross_profit: 232262.5,
    gross_loss: -154037.5,
    commission: 0,
    profit_factor: 1.52,
    profit_per_month: 501.5,
    profit_per_quarter: 1504.5,
    max_drawdown: -17762.5,
    sharpe_ratio: 0.2,
    sortino_ratio: 1.04,
    ulcer_index: 0.04,
    r_squared: 0.86,
    probability: 1.22,
    total_of_trades: 678,
    percent_profitable: 103.01,
    winning_trades: 339,
    losing_trades: 310,
    even_trades: 29,
    total_slippage: 0,
    avg_trade: 139.65,
    avg_winning_trade: 737.56,
    avg_losing_trade: -555.46,
    ratio_win_loss: 1.34,
    max_consec_winners: 16,
    max_consec_losers: 13,
    largest_winning_trade: 4656.25,
    largest_losing_trade: -1750,
    avg_trades_per_day: 0.1,
    avg_time_in_market: 228.11,
    avg_bars_in_trade: 24.64,
    max_time_to_recover: 926.48,
    longest_flat_period: 164.76,
    avg_mae: 450.86,
    avg_mfe: 719.2,
    avg_etd: 579.55,
    avg_trades_per_quarter: 16.95,
  },
  chart_data: [
    ["1/1/2008", 1527.58],
    ["2/1/2008", 2912.92],
    ["3/1/2008", 870.68],
    ["4/1/2008", 2409.47],
    ["5/1/2008", 2920.66],
    ["6/1/2008", 3873.24],
    ["7/1/2008", 3529.7],
    ["8/1/2008", 4797.82],
    ["9/1/2008", 4856.65],
    ["10/1/2008", 7596.3],
    ["11/1/2008", 9268.28],
    ["12/1/2008", 9015.69],
    ["1/1/2009", 10326.46],
    ["2/1/2009", 8435.94],
    ["3/1/2009", 9950.16],
    ["4/1/2009", 10311.15],
    ["5/1/2009", 10676.88],
    ["6/1/2009", 14781.36],
    ["7/1/2009", 12222.78],
    ["8/1/2009", 12154.46],
    ["9/1/2009", 16924.02],
    ["10/1/2009", 16942.12],
    ["11/1/2009", 12178.16],
    ["12/1/2009", 11770.4],
    ["1/1/2010", 16037.8],
    ["2/1/2010", 11645.61],
    ["3/1/2010", 16533.48],
    ["4/1/2010", 16559.34],
    ["5/1/2010", 11886.12],
    ["6/1/2010", 13356.16],
    ["7/1/2010", 13882.45],
    ["8/1/2010", 13412.62],
    ["9/1/2010", 13545.16],
    ["10/1/2010", 16758.02],
    ["11/1/2010", 13121.45],
    ["12/1/2010", 13207.87],
    ["1/1/2011", 16158.44],
    ["2/1/2011", 16475.68],
    ["3/1/2011", 12647.52],
    ["4/1/2011", 12939.76],
    ["5/1/2011", 16679.12],
    ["6/1/2011", 13610.23],
    ["7/1/2011", 13946.65],
    ["8/1/2011", 16829.62],
    ["9/1/2011", 17653.97],
    ["10/1/2011", 18578.32],
    ["11/1/2011", 17867.54],
    ["12/1/2011", 18216.89],
    ["1/1/2012", 17890.38],
    ["2/1/2012", 19217.44],
    ["3/1/2012", 19298.04],
    ["4/1/2012", 20178.64],
    ["5/1/2012", 19305.36],
    ["6/1/2012", 19257.08],
    ["7/1/2012", 19101.9],
    ["8/1/2012", 17979.59],
    ["9/1/2012", 19320.86],
    ["10/1/2012", 18153.94],
    ["11/1/2012", 18259.54],
    ["12/1/2012", 17872.9],
    ["1/1/2013", 18410.5],
    ["2/1/2013", 17948.86],
    ["3/1/2013", 18269.98],
    ["4/1/2013", 18681.3],
    ["5/1/2013", 21348.42],
    ["6/1/2013", 18866.64],
    ["7/1/2013", 18667.93],
    ["8/1/2013", 21274.7],
    ["9/1/2013", 18562.97],
    ["10/1/2013", 19274.82],
    ["11/1/2013", 21567.36],
    ["12/1/2013", 19153.91],
    ["1/1/2014", 19546.58],
    ["2/1/2014", 19493.13],
    ["3/1/2014", 19052.61],
    ["4/1/2014", 21417.34],
    ["5/1/2014", 22044.06],
    ["6/1/2014", 22377.68],
    ["7/1/2014", 20184.5],
    ["8/1/2014", 24134.14],
    ["9/1/2014", 20888.16],
    ["10/1/2014", 21887.51],
    ["11/1/2014", 26940.16],
    ["12/1/2014", 23285.35],
    ["1/1/2015", 23737.07],
    ["2/1/2015", 24530.17],
    ["3/1/2015", 25677.58],
    ["4/1/2015", 25158.18],
    ["5/1/2015", 25457.1],
    ["6/1/2015", 27089.64],
    ["7/1/2015", 32812.1],
    ["8/1/2015", 28051.7],
    ["9/1/2015", 27256.22],
    ["10/1/2015", 27599.75],
    ["11/1/2015", 27851.04],
    ["12/1/2015", 27695],
    ["1/1/2016", 29701.46],
    ["2/1/2016", 29511.8],
    ["3/1/2016", 30944.34],
    ["4/1/2016", 31310.07],
    ["5/1/2016", 31228.17],
    ["6/1/2016", 32857.26],
    ["7/1/2016", 38759],
    ["8/1/2016", 38768.48],
    ["9/1/2016", 34120.4],
    ["10/1/2016", 33496.26],
    ["11/1/2016", 33980.31],
    ["12/1/2016", 34468.67],
    ["1/1/2017", 42300.36],
    ["2/1/2017", 42913.72],
    ["3/1/2017", 35198.19],
    ["4/1/2017", 34861.98],
    ["5/1/2017", 43161.98],
    ["6/1/2017", 42725.34],
    ["7/1/2017", 43530.94],
    ["8/1/2017", 36373.61],
    ["9/1/2017", 36908.09],
    ["10/1/2017", 47036.96],
    ["11/1/2017", 37180.93],
    ["12/1/2017", 36708.73],
    ["1/1/2018", 50488.24],
    ["2/1/2018", 56614.96],
    ["3/1/2018", 59263.66],
    ["4/1/2018", 41924.88],
    ["5/1/2018", 42839.1],
    ["6/1/2018", 40974.44],
    ["7/1/2018", 42323.36],
    ["8/1/2018", 41887.15],
    ["9/1/2018", 41798.57],
    ["10/1/2018", 42875.29],
    ["11/1/2018", 44737.14],
    ["12/1/2018", 45193.17],
    ["1/1/2019", 46667.95],
    ["2/1/2019", 62587.3],
    ["3/1/2019", 46841.22],
    ["4/1/2019", 63787.72],
    ["5/1/2019", 47073.97],
    ["6/1/2019", 64710.12],
    ["7/1/2019", 64529.94],
    ["8/1/2019", 49792.06],
    ["9/1/2019", 48468.35],
    ["10/1/2019", 48615.33],
    ["11/1/2019", 69395],
    ["12/1/2019", 48979.98],
    ["1/1/2020", 49879.76],
    ["2/1/2020", 50665.1],
    ["3/1/2020", 55691.17],
    ["4/1/2020", 57826.94],
    ["5/1/2020", 57326.29],
    ["6/1/2020", 58801.07],
    ["7/1/2020", 55174.34],
    ["8/1/2020", 56071.75],
    ["9/1/2020", 59798.9],
    ["10/1/2020", 62017.86],
    ["11/1/2020", 59990.7],
    ["12/1/2020", 58434.23],
    ["1/1/2021", 58988.75],
    ["2/1/2021", 57182.28],
    ["3/1/2021", 55652.75],
    ["4/1/2021", 56706.84],
    ["5/1/2021", 55838.3],
    ["6/1/2021", 55358.77],
  ],
};

const tour_custom_3_data = {
  all_trades: {
    total_net_profit: 205100,
    gross_profit: 625087.5,
    gross_loss: -419987.5,
    commission: 0,
    profit_factor: 2.12,
    profit_per_month: 1316.6,
    profit_per_quarter: 3949.8,
    max_drawdown: -17225,
    sharpe_ratio: 0.33,
    sortino_ratio: 3.99,
    ulcer_index: 0.02,
    r_squared: 0.92,
    probability: 0,
    total_of_trades: 2448,
    percent_profitable: 175.6,
    winning_trades: 1301,
    losing_trades: 1035,
    even_trades: 112,
    total_slippage: 0,
    avg_trade: 150.72,
    avg_winning_trade: 550.05,
    avg_losing_trade: -435.61,
    ratio_win_loss: 1.33,
    max_consec_winners: 34,
    max_consec_losers: 20,
    largest_winning_trade: 3766.67,
    largest_losing_trade: -1570.83,
    avg_trades_per_day: 0.25,
    avg_time_in_market: 228.36,
    avg_bars_in_trade: 20.3,
    max_time_to_recover: 355,
    longest_flat_period: 114.78,
    avg_mae: 358.81,
    avg_mfe: 558.39,
    avg_etd: 407.67,
    avg_trades_per_quarter: 61.2,
  },
  long_trades: {
    total_net_profit: 108850,
    gross_profit: 366762.5,
    gross_loss: -257912.5,
    commission: 0,
    profit_factor: 2.19,
    profit_per_month: 700.09,
    profit_per_quarter: 2100.27,
    max_drawdown: -18675,
    sharpe_ratio: 0.24,
    sortino_ratio: 2.37,
    ulcer_index: 0.01,
    r_squared: 0.86,
    probability: 0.1,
    total_of_trades: 1697,
    percent_profitable: 181.42,
    winning_trades: 915,
    losing_trades: 700,
    even_trades: 82,
    total_slippage: 0,
    avg_trade: 130.26,
    avg_winning_trade: 462.46,
    avg_losing_trade: -400.34,
    ratio_win_loss: 1.26,
    max_consec_winners: 34,
    max_consec_losers: 16,
    largest_winning_trade: 3237.5,
    largest_losing_trade: -1450,
    avg_trades_per_day: 0.17,
    avg_time_in_market: 230.29,
    avg_bars_in_trade: 21.1,
    max_time_to_recover: 537.34,
    longest_flat_period: 149.46,
    avg_mae: 329.62,
    avg_mfe: 482.47,
    avg_etd: 352.21,
    avg_trades_per_quarter: 42.425,
  },
  short_trades: {
    total_net_profit: 96250,
    gross_profit: 258325,
    gross_loss: -162075,
    commission: 0,
    profit_factor: 2.09,
    profit_per_month: 619.42,
    profit_per_quarter: 1858.26,
    max_drawdown: -18850,
    sharpe_ratio: 0.22,
    sortino_ratio: 2.11,
    ulcer_index: 0.03,
    r_squared: 0.88,
    probability: 0.82,
    total_of_trades: 751,
    percent_profitable: 167.39,
    winning_trades: 386,
    losing_trades: 335,
    even_trades: 30,
    total_slippage: 0,
    avg_trade: 175.41,
    avg_winning_trade: 676.55,
    avg_losing_trade: -477.47,
    ratio_win_loss: 1.46,
    max_consec_winners: 22,
    max_consec_losers: 16,
    largest_winning_trade: 3650,
    largest_losing_trade: -1475,
    avg_trades_per_day: 0.08,
    avg_time_in_market: 217.14,
    avg_bars_in_trade: 17.65,
    max_time_to_recover: 846.66,
    longest_flat_period: 242.84,
    avg_mae: 396.4,
    avg_mfe: 666.22,
    avg_etd: 490.82,
    avg_trades_per_quarter: 18.775,
  },
  chart_data: [
    ["1/1/2008", 880.6],
    ["2/1/2008", 1794.39],
    ["3/1/2008", 870.68],
    ["4/1/2008", 2409.47],
    ["5/1/2008", 2920.66],
    ["6/1/2008", 3873.24],
    ["7/1/2008", 3529.7],
    ["8/1/2008", 3300.41],
    ["9/1/2008", 4856.65],
    ["10/1/2008", 7596.3],
    ["11/1/2008", 9268.28],
    ["12/1/2008", 6640.2],
    ["1/1/2009", 10326.46],
    ["2/1/2009", 8435.94],
    ["3/1/2009", 9950.16],
    ["4/1/2009", 10311.15],
    ["5/1/2009", 10676.88],
    ["6/1/2009", 8627.1],
    ["7/1/2009", 9054.84],
    ["8/1/2009", 12154.46],
    ["9/1/2009", 16924.02],
    ["10/1/2009", 10416.1],
    ["11/1/2009", 12178.16],
    ["12/1/2009", 9121.5],
    ["1/1/2010", 16037.8],
    ["2/1/2010", 11645.61],
    ["3/1/2010", 16533.48],
    ["4/1/2010", 16559.34],
    ["5/1/2010", 9359.85],
    ["6/1/2010", 13356.16],
    ["7/1/2010", 10729.82],
    ["8/1/2010", 10272.35],
    ["9/1/2010", 13545.16],
    ["10/1/2010", 16758.02],
    ["11/1/2010", 9963.15],
    ["12/1/2010", 10131.97],
    ["1/1/2011", 16158.44],
    ["2/1/2011", 16475.68],
    ["3/1/2011", 9807.11],
    ["4/1/2011", 9917.31],
    ["5/1/2011", 10303.13],
    ["6/1/2011", 13610.23],
    ["7/1/2011", 13946.65],
    ["8/1/2011", 16829.62],
    ["9/1/2011", 13254.94],
    ["10/1/2011", 18578.32],
    ["11/1/2011", 17867.54],
    ["12/1/2011", 18216.89],
    ["1/1/2012", 17890.38],
    ["2/1/2012", 19217.44],
    ["3/1/2012", 19298.04],
    ["4/1/2012", 20178.64],
    ["5/1/2012", 19305.36],
    ["6/1/2012", 12042.54],
    ["7/1/2012", 19101.9],
    ["8/1/2012", 17979.59],
    ["9/1/2012", 19320.86],
    ["10/1/2012", 18153.94],
    ["11/1/2012", 13939.4],
    ["12/1/2012", 17872.9],
    ["1/1/2013", 18410.5],
    ["2/1/2013", 17948.86],
    ["3/1/2013", 18269.98],
    ["4/1/2013", 18681.3],
    ["5/1/2013", 21348.42],
    ["6/1/2013", 18866.64],
    ["7/1/2013", 18667.93],
    ["8/1/2013", 21274.7],
    ["9/1/2013", 18562.97],
    ["10/1/2013", 19274.82],
    ["11/1/2013", 21567.36],
    ["12/1/2013", 14699.73],
    ["1/1/2014", 19546.58],
    ["2/1/2014", 19493.13],
    ["3/1/2014", 14614.23],
    ["4/1/2014", 14156.72],
    ["5/1/2014", 22044.06],
    ["6/1/2014", 22377.68],
    ["7/1/2014", 20184.5],
    ["8/1/2014", 24134.14],
    ["9/1/2014", 16431.18],
    ["10/1/2014", 21887.51],
    ["11/1/2014", 26940.16],
    ["12/1/2014", 23285.35],
    ["1/1/2015", 17751.57],
    ["2/1/2015", 24530.17],
    ["3/1/2015", 18998.12],
    ["4/1/2015", 25158.18],
    ["5/1/2015", 18623.55],
    ["6/1/2015", 27089.64],
    ["7/1/2015", 32812.1],
    ["8/1/2015", 20313.19],
    ["9/1/2015", 27256.22],
    ["10/1/2015", 27599.75],
    ["11/1/2015", 27851.04],
    ["12/1/2015", 19911.6],
    ["1/1/2016", 29701.46],
    ["2/1/2016", 29511.8],
    ["3/1/2016", 30944.34],
    ["4/1/2016", 31310.07],
    ["5/1/2016", 22414.46],
    ["6/1/2016", 23714.6],
    ["7/1/2016", 22454.7],
    ["8/1/2016", 22376.25],
    ["9/1/2016", 25386.43],
    ["10/1/2016", 25030.25],
    ["11/1/2016", 33980.31],
    ["12/1/2016", 34468.67],
    ["1/1/2017", 42300.36],
    ["2/1/2017", 42913.72],
    ["3/1/2017", 26342.75],
    ["4/1/2017", 25770.19],
    ["5/1/2017", 43161.98],
    ["6/1/2017", 25216.53],
    ["7/1/2017", 25559.2],
    ["8/1/2017", 27168.6],
    ["9/1/2017", 36908.09],
    ["10/1/2017", 47036.96],
    ["11/1/2017", 37180.93],
    ["12/1/2017", 36708.73],
    ["1/1/2018", 50488.24],
    ["2/1/2018", 56614.96],
    ["3/1/2018", 33496.68],
    ["4/1/2018", 30554.36],
    ["5/1/2018", 31266.71],
    ["6/1/2018", 40974.44],
    ["7/1/2018", 42323.36],
    ["8/1/2018", 41887.15],
    ["9/1/2018", 41798.57],
    ["10/1/2018", 42875.29],
    ["11/1/2018", 33021.15],
    ["12/1/2018", 45193.17],
    ["1/1/2019", 46667.95],
    ["2/1/2019", 62587.3],
    ["3/1/2019", 34289.25],
    ["4/1/2019", 63787.72],
    ["5/1/2019", 47073.97],
    ["6/1/2019", 64710.12],
    ["7/1/2019", 64529.94],
    ["8/1/2019", 49792.06],
    ["9/1/2019", 35585.21],
    ["10/1/2019", 48615.33],
    ["11/1/2019", 69395],
    ["12/1/2019", 48979.98],
    ["1/1/2020", 49879.76],
    ["2/1/2020", 37040.08],
    ["3/1/2020", 55691.17],
    ["4/1/2020", 57826.94],
    ["5/1/2020", 57326.29],
    ["6/1/2020", 58801.07],
    ["7/1/2020", 55174.34],
    ["8/1/2020", 56071.75],
    ["9/1/2020", 59798.9],
    ["10/1/2020", 44832.29],
    ["11/1/2020", 59990.7],
    ["12/1/2020", 58434.23],
    ["1/1/2021", 58988.75],
    ["2/1/2021", 57182.28],
    ["3/1/2021", 41004.26],
    ["4/1/2021", 56706.84],
    ["5/1/2021", 55838.3],
    ["6/1/2021", 55358.77],
  ],
};

const getDummySimulationData = (params) => {
  const { portfolioType, step } = params;
  let data = null;

  if (portfolioType === "preMade") {
    if (step === 1) data = tour_preMade_1_data;
    if (step === 2) data = tour_preMade_2_data;
    if (step === 3) data = tour_preMade_3_data;
  } else if (portfolioType === "custom") {
    if (step === 1) data = tour_custom_1_data;
    if (step === 2) data = tour_custom_2_data;
    if (step === 3) data = tour_custom_3_data;
  }

  return new Promise((resolve, reject) => {
    setTimeout(() => {
      if (true) {
        resolve(data);
      } else {
        reject(new Error("Get data failed..."));
      }
    }, 500);
  });
};

export default getDummySimulationData;
